.popup-overlay {
  background: rgba(0, 0, 0, 0.3);
}
/* animation */

@keyframes anvil {
  0% {
    transform: scale(0.1);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}

.popup-content {
  animation: anvil 0.3s ease;
}
