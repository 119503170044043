@import "../../styles/colors";
@import "../../styles/media";

.card {
  width: calc(100% / 1 - 30px);
  height: 450px;
  margin: 0 15px 40px;
  padding: 40px 5px 0;
  border-radius: 10px;
  box-shadow: $box-shadow-big;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  background-position-y: 35%;

  &__center {
    text-align: center;
    background-position-x: center;
  }
}

.title {
  font-family: MontserratBold, sans-serif;
  font-size: 18px;
  line-height: 20px;
  white-space: pre-line;
  margin: 0 0 17px;
}

.text {
  font-family: MontserratLight, sans-serif;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 1.2px;
  white-space: pre-line;
  margin: 0;
}

@media (min-width: $xs) {
  .card {
    padding: 40px 25px 0;
  }
}

@media (min-width: $sm) {
  .card {
    padding: 90px 35px 0;
    text-align: left;
  }

  .title {
    font-size: 26px;
    line-height: 30px;
    margin: 0 0 23px;
  }
}

@media (min-width: $md) {
  .card {
    padding: 40px 25px 0;
    width: calc(100% / 2 - 30px);
    height: 450px;
    text-align: center;
  }
}

@media (min-width: $lg) {
  .card {
    padding: 90px 35px 0;
    height: 430px;
    text-align: left;
  }
}
