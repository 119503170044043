@import "../../styles/colors";
@import "../../styles/media";

.mainSlide {
  padding: 70px 30px 0px 30px;
  // margin-top: 40px;
  margin-bottom: 64px;
  height: 519px;
  color: $white-color;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right 64% bottom 30%;
  border-radius: 10px;
  box-shadow: $box-shadow-big;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  text-align: center;
  text-decoration: none;
}

.pretitle {
  font-family: Montserrat, sans-serif;
  font-size: 14px;
}

.title {
  h3 {
    font-family: MontserratExtraBold, sans-serif;
    font-size: 20px;
    line-height: 28px;
    text-transform: uppercase;
    margin: 9px 0 22px;
    // max-width: 194px;
  }
}

.description {
  // width: 60%;
  // max-height: 199px;
  // overflow: hidden;
  margin-bottom: auto;

  p {
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 1px;
    margin: 0 0 20px;

    span {
      font-family: MontserratSemi Bold, sans-serif;
    }
  }
}

.buttons {
  display: none;
  flex-wrap: wrap;
  margin: auto auto 0;
  width: 100%;
  align-items: center;
  justify-content: center;

  &__margin {
    margin-top: 70px;
  }

  a,
  button {
    width: 100%;
  }
}

.logo {
  position: absolute;
  top: 11px;
  right: 50%;
  transform: translateX(50%);

  img {
    width: 47px;
    height: auto;
    vertical-align: middle;
  }
}

@media (min-width: $sm) {
  .mainSlide {
    padding: 100px 30px 55px 20px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .buttons {
    display: flex;
  }
}

@media (min-width: $md) {
  .mainSlide {
    text-align: left;
    padding: 80px 25px 45px 25px;
    height: 590px;
    width: 32%;
    background-position: 64% top;
    margin-right: 0;
    margin-bottom: 0;
    margin-top: 55px;
  }

  .pretitle {
    font-size: 16px;
  }

  .title {
    h3 {
      font-size: 25px;
      line-height: 30px;
      max-width: 194px;
      margin: 6px 0 15px;

      span {
        display: inline;
      }
    }
  }

  .description {
    margin-bottom: 0;

    p {
      font-size: 14px;
      line-height: 21px;
    }
  }

  .buttons {
    // width: 530px;

    a,
    button {
      width: auto;
    }

    a {
      margin-bottom: 0;
    }
  }

  .logo {
    position: absolute;
    top: 13px;
    right: 19px;
    transform: translateX(0%);

    img {
      width: 100%;
    }
  }
}

@media (min-width: $ml) {
  .mainSlide {
    height: 610px;
  }

  .pretitle {
    font-size: 20px;
  }

  .title {
    h3 {
      font-size: 28px;
      line-height: 36px;
      margin: 9px 0 22px;
    }
  }

  .description {
    p {
      font-size: 16px;
      line-height: 22px;
    }
  }
}

@media (min-width: $lg) {
  .mainSlide {
    padding: 100px 30px 55px 30px;
    height: 712px;
  }

  .buttons {
    &__margin {
      margin-top: 120px;
    }
  }
}

@media (min-width: $xl) {
}
