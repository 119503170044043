@import "../../styles/colors";
@import "../../styles/media";

.mainSlide {
  padding: 105px 20px 30px 20px;
  margin-top: 55px;
  text-align: left;
  height: 630px;
  color: $white-color;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 64% top;
  border-radius: 10px;
  box-shadow: $box-shadow-big;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.title {
  h3 {
    font-family: MontserratExtraBold, sans-serif;
    font-size: 20px;
    line-height: 28px;
    text-transform: uppercase;
    margin: 0;
    max-width: 520px;

    span {
      display: none;
    }
  }

  div {
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  &__image {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    img {
      margin-bottom: -9px;
      margin-left: -20px;
      width: 98px;
      height: auto;
    }
  }
}

.description {
  width: 60%;
  max-height: 199px;
  overflow: hidden;
  margin-bottom: auto;

  p {
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 1px;
    margin: 0 0 20px;

    &:last-of-type {
      display: none;
    }
  }
}

.buttons {
  //   display: none;
  display: flex;
  flex-wrap: wrap;
  margin-top: auto;
  //   width: 530px;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  &__margin {
    margin-top: 70px;
  }

  a,
  button {
    width: 100%;
    margin-bottom: 10px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

@media (min-width: $sm) {
  .mainSlide {
    padding: 120px 30px 20px 30px;
    background-position: center top;
  }

  .description {
    width: 70%;

    p {
      &:last-of-type {
        display: initial;
      }
    }
  }

  .buttons {
    width: 100%;

    a,
    button {
      width: auto;
      margin-bottom: 0;
    }
  }
}

@media (min-width: $md) {
  .mainSlide {
    padding: 110px 35px 60px 35px;
    height: 590px;
    width: 66%;
  }

  .title {
    h3 {
      font-size: 28px;
      line-height: initial;

      span {
        display: inline;
      }
    }

    div {
      font-size: 20px;
      margin-bottom: 17px;
    }

    &__image {
      display: flex;
      flex-direction: row;
      align-items: center;

      img {
        margin-bottom: 0;
        // margin-left: 0;
        margin-right: -15px;
        width: 110px;
      }
    }
  }

  .description {
    width: 350px;
    // max-height: 150px;
    margin-bottom: 0;
  }
}

@media (min-width: $ml) {
  .mainSlide {
    padding: 110px 35px 60px 50px;
    height: 610px;
  }

  .buttons {
    width: 470px;
  }
}

@media (min-width: $lg) {
  .mainSlide {
    padding: 160px 55px 80px 100px;
    height: 712px;
  }

  .buttons {
    width: 530px;
  }
}

@media (min-width: $xl) {
}
