@import "../../styles/media";

.arrowDown {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 26px 0 24px;
  width: 100%;

  img {
    vertical-align: middle;
  }

  @media (min-width: $md) {
    display: none;
  }
}
