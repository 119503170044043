@import "../../styles/colors";
@import "../../styles/media";

.footerWrapper {
  // background-color: $background-color;
}

.footer {
  align-items: center;
  display: flex;
  padding: 40px 0 40px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  @media (min-width: $lg) {
    justify-content: flex-start;
    padding: 5px 0 5px;
  }

  @media (min-width: $xl) {
    // max-width: 1366px;
    padding: 10px $basePadding 10px;
    margin: 0 auto;
  }
}

.footerLeft {
  align-items: center;
  display: flex;
}

.footerHide {
  display: none;
  @media (min-width: $lg) {
    display: flex;
  }
}
.footerLogo {
  margin-left: 30px;
  margin-right: 6px;
  height: 50px;

  > div {
    height: 100%;
  }
}
.footerCopy {
  display: flex;
  align-items: center;
  color: $footer-text;
  margin-left: auto;
  font-family: MontserratLight, sans-serif;
  font-size: 16px;
  flex-basis: 100%;
  justify-content: center;
  margin-top: 10px;

  img {
    margin-right: 6px;
  }
}

@media (min-width: $md) {
  .footer {
  }

  .footerCopy {
    flex-basis: initial;
    margin-top: 0;
  }
}
