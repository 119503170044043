@import "../../styles/colors";
@import "../../styles/media";

.button {
  border: 2px solid $violent-color;
  border-radius: 5px;
  background: $violent-color;
  box-shadow: none;
  color: $white-color;
  font-family: Montserrat, sans-serif;
  display: inline-block;
  font-size: 1rem;
  padding: 9px;
  text-align: center;
  text-decoration: none;
  -webkit-appearance: none;
  transition: all 0.5s ease;
  min-width: 140px;
  line-height: normal;
  max-width: 100%;

  &.bold {
    font-family: MontserratSemiBold, sans-serif;
  }

  &:hover {
    background-color: transparent;
    color: $violent-color;
    cursor: pointer;
  }

  &:focus {
    outline: $focus;
  }

  // &.outline {
  //   background: transparent;
  //   color: $violent-color;

  //   &:hover {
  //     background-color: $violent-color;
  //     color: $white-color;
  //   }
  // }

  &.shadow {
    box-shadow: $box-shadow;
  }

  &.white {
    background: $white-color;
    color: $text-color;
    border-color: $white-color;

    &:hover {
      background-color: $black-squeeze-color;
      border-color: $black-squeeze-color;
      color: $text-color;
    }
  }

  &.white-bordered {
    background: $white-color;
    color: $text-color;
    border-color: $text-color;

    &:hover {
      background-color: $black-squeeze-color;
      border-color: $text-color;
      color: $text-color;
    }
  }

  &.white-shadow {
    background: $white-color;
    color: $blue-form;
    border-color: $white-color;
    text-transform: uppercase;
    border-radius: 10px;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);

    &:disabled {
      background: $white-color;
      border-color: $white-color;
    }
  }

  &.blue-shadow {
    border-radius: 10px;
    background: $facebook-color;
    color: $white-color;
    border-color: $facebook-color;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  }

  // &.transparent {
  //   background: transparent;
  //   color: $white-color;
  //   border-color: $white-color;

  //   &:hover {
  //     background-color: $white-color;
  //     border-color: $text-color-2;
  //     color: $text-color-2;
  //   }
  // }

  // &.white.outline {
  //   background: transparent;
  //   color: $white-color;
  //   border-color: $white-color;

  //   &:hover {
  //     background-color: $white-color;
  //     color: $text-color;
  //   }
  // }

  &.fullWidth {
    font-size: 20px;
    padding-bottom: 26px;
    padding-top: 26px;
    width: 100%;
  }

  &.md {
    padding-top: 14px;
    padding-bottom: 14px;
    min-width: 185px;
  }

  @media (min-width: $sm640) {
    &.md {
      padding-top: 14px;
      padding-bottom: 14px;
      min-width: 250px;
    }
    min-width: 250px;
  }

  @media (min-width: $md) {
    &.md {
      padding-top: 10px;
      padding-bottom: 10px;
      min-width: 185px;
    }
    min-width: 200px;
  }

  @media (min-width: $ml) {
    &.md {
      padding-top: 14px;
      padding-bottom: 14px;
      min-width: 220px;
    }
  }

  @media (min-width: $lg) {
    &.md {
      min-width: 250px;
    }
    &.h {
      min-width: 200px;
    }
    min-width: 250px;
  }

  &[disabled] {
    border-color: $silver-color;
    background-color: $silver-color;
    pointer-events: none;

    &.outline {
      background-color: transparent;
    }
  }
}
