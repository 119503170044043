// Colors

$background-color: #f8f9f8; // primary background
$text-color: #000000;
$text-color-2: #404040;
$text-color-3: #666666;
$text-color-4: #a8a8a8;
$white-color: #ffffff;
$violent-color: #646c9a;
$violent-dark-color: #494e6d;
$violent-light-color: #9197bf;
$grey-color: #52575d;
$silver-color: #b3b8bd;
$black-squeeze-color: #ebf2f7;
$tropical-blue-color: #bed4f8;
$zumthor-color: #eaf2ff;
$grey-light-color: #a19daf;
$blue-color: #5a90bc;
$blue-dark-color: #3b5998;
$blue-form: #022e54;
$blue-form-dark: #5e666d;
$dew-color: #f6fffe;
$placeholder-color: #7a7a7a;
$campaign-color: #646c9a;
$pink-color: #ffbfdb;
$mint-color: #d7f9e8;
$foam-color: #daf6fb;
$shalimar-color: #fff9bf;
$bright-turquoise-color: #0bf2dd;
$san-juan-color: #364378;
$oracle-color: #367872;
$light-oracle-color: #32beb1;
$light-grey: #f2f2f2;
$light-grey-background: #efecec;
$cyan: #71efef;
$red-pink: #f68b8b;
$light-green: #1bb143;
$cyanBg: #edf6f6;
$light-cyan: #f2f9ff;
$help-grey: #8f8f8f;
$blue-grey: #eff1f2;
$table-header: #565278;
$table-border: #d4d4d4;
$delete-border: #707070;

$green-color: #19ae3c;
$grass-color: #649a7f;
$yellow-color: #c9c300;
$orangelight-color: #e4b620;
$red-color: #c91500;
$orange-color: #975040;
$blue-bright-color: #508ff4;
$purple-color: #9a2f9e;

$facebook-color: #1f8efa;
$twitter-color: #1da1f2;
$tiktok-color: #69c9d0;
$instagram-color: #e1306c;

$focus: 1px dotted $violent-color;

$error: #ff0000;
$box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
$box-shadow-big: 20px 30px 60px 0px rgba(0, 0, 0, 0.16);
$box-shadow-faded: 0px 8px 16px rgba(0, 0, 0, 0.1);

$color-live: #29e330;
$color-suspended: #8a37c1;
$color-rejected: $red-color;
$color-finished: #c13798;
$color-wa: #c1c137;

$form-bg-1: #feffff;
// $form-bg-2: #e5f8ff;
$form-logo-1: #7b7a7a;
$form-logo-2: #312e2e;
$form-separator: #dbdbdb;

$facebook-bg: #f0f2f5;
$twitter-border: #ececec;

$header-logo: #262f61;

$gradient-bg: #e5f8ff;

$slide-link: #07a9c2;
$slide-blue: #5682e2;

$footer-text: #878282;

$step-bg: #f5f5f5;
