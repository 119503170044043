@mixin topIcon($size: 60px) {
  position: absolute;
  width: $size;
  height: $size;
  top: $size * -0.5;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 50%;
  display: flex;
  > img {
    width: 50%;
    height: 50%;
    margin: auto;
  }
}

@mixin maskIcon($url, $color) {
  background-color: $color;
  mask-size: 100%;
  mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-image: url($url);
  mask-image: url($url);
}
