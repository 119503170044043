@import "../../styles/colors";
@import "../../styles/media";

.slidePopUp {
  text-align: center;
  color: $blue-form;
  padding-top: 100px;
  padding-right: 5px;
  padding-bottom: 10px;
  position: relative;
  background: linear-gradient(
    135deg,
    $white-color 0%,
    $form-bg-1 5%,
    $gradient-bg 60%,
    $gradient-bg 100%
  );

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    border-radius: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
    border: none;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #bebdbd;
    border-radius: 10px;
    height: 100px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .slide-pane__content {
    padding: 0 20px 40px 20px;
    position: static;
  }

  &__close {
    cursor: pointer;
    position: absolute;
    top: 36px;
    right: 50%;
    transform: translateX(50%);
  }

  &__overlay {
    z-index: 10;
  }

  &--v1 {
    border-radius: 0 20px 20px 0;
  }

  &--v2 {
    border-radius: 20px 0 0 20px;
    padding-right: 0;
    padding-left: 25px;

    .slide-pane__content {
      direction: rtl;
      padding: 0 145px 90px 120px;
    }

    .slidePopUp__close {
      right: initial;
      left: 30px;
    }
  }

  &--v3 {
    border-radius: 20px 20px 0 0;
    margin-left: auto;
    margin-right: auto;
  }

  &__pretitle {
    text-transform: uppercase;
    font-family: Montserrat;
    font-size: 14px;
  }

  &__header {
    margin: 8px 0 48px;
    font-family: MontserratExtraBold;
    font-size: 20px;
  }

  &__title {
    font-family: MontserratBold;
    font-size: 20px;
    text-transform: uppercase;
    margin: 40px 0 15px;

    &-small {
      font-family: MontserratSemiBold;
      text-transform: initial;
      margin: 15px 0;
    }
  }

  &__text {
    font-family: Montserrat;
    font-size: 16px;
    line-height: 24px;
    white-space: pre-line;
    margin: 0;

    span {
      font-family: MontserratBold;
    }

    &-bold {
      margin-bottom: 0;
      font-family: MontserratBold;
    }
  }

  &__btn {
    a {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        margin-right: 10px;
      }
    }
  }

  @media (min-width: $sm) {
    &__pretitle {
      font-size: 16px;
    }

    &__header {
      margin: 15px 0 54px;
      font-size: 26px;
    }

    &__title {
      font-size: 22px;
    }

    &__subtitle {
      font-size: 22px;
    }

    &__text {
      font-size: 18px;
      line-height: 26px;
    }
  }

  @media (min-width: $md) {
    padding-top: 70px;
    padding-right: 25px;
    background: $white-color;

    .slide-pane__content {
      padding: 0 120px 90px 145px;
    }

    &__close {
      top: 24px;
      right: 30px;
      transform: translateX(0);
    }

    &__btn {
      display: none;
    }
  }
}

.ReactModal__Body--open {
  overflow: hidden !important;
}
