@import "../../styles/colors";
@import "../../styles/media";

.steps {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 -15px;
  padding-bottom: 30px;

  @media (min-width: $md) {
    padding-bottom: 90px;
  }
}
