@import "../../styles/media";

.logos {
  align-items: center;
  display: none;
  justify-content: center;
  padding: 85px 0 45px;
  max-width: 100%;

  img {
    margin: 0 10px;
    max-width: calc(25% - 20px);
  }

  img:last-child {
    // display: none;
  }

  @media (min-width: $md) {
    display: flex;
  }

  @media (min-width: $lg) {
    padding: 60px 0 50px;
    img {
      margin: 0 25px;
      max-width: initial;

      &:last-child {
        // display: initial;
      }
    }
  }
}
