.social {
  list-style: none;
  padding: 0;
  margin: 0 -7px;
  display: flex;
  align-items: center;

  li {
    margin: 0 7px;

    img {
      max-width: 80%;
      height: auto;
      vertical-align: middle;
    }
  }
}
