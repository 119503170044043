@import "../../styles/colors";
@import "../../styles/media";

.step {
  box-shadow: $box-shadow-big;
  width: calc(100% / 1 - 30px);
  min-height: 300px;
  border-radius: 10px;
  padding: 26px 25px 244px;
  background-color: $step-bg;
  margin: 0 15px;
  background-size: 163px;
  background-repeat: no-repeat;
  background-position: bottom -100px right 50%;
  text-align: center;
  margin-bottom: 30px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.number {
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  line-height: 30px;
}

.title {
  font-family: MontserratBold, sans-serif;
  font-size: 18px;
  line-height: 22px;
  white-space: pre-line;
  margin: 10px 0 10px;
}

.description {
  font-family: MontserratLight, sans-serif;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  white-space: pre-line;
}

@media (min-width: $md) {
  .step {
    width: calc(100% / 2 - 30px);
    background-position: bottom -38px right -30px;
    padding: 65px 25px 0;
    text-align-last: left;
    background-size: 146px;
    margin-bottom: 30px;

    &:last-of-type {
      margin: 0 auto;
    }
  }

  .title {
    margin: 7px 0 12px;
  }
}

@media (min-width: $lg) {
  .step {
    width: calc(100% / 3 - 30px);
    margin-bottom: 0;
  }
}
