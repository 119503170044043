@import "../../styles/colors";
@import "../../styles/media";

.faqs {
  margin-top: 0;
  margin-bottom: 40px;
  width: 100%;
  box-shadow: $box-shadow-big;
  padding: 35px 0;
  border-radius: 10px;
  text-align: center;
  background-color: $step-bg;
  z-index: 2;
  overflow: overlay;
  cursor: pointer;
  display: none;

  h3 {
    font-family: MontserratBold, sans-serif;
    font-size: 26px;
    line-height: 30px;
    text-transform: uppercase;
    margin: 0;
  }

  &__mobile {
    display: block;
  }

  @media (min-width: $sm) {
    display: block;
    margin-top: 54px;

    &__mobile {
      display: none;
    }
  }
}
